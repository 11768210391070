import React, { useState, useEffect } from "react";
import "./Loyalty.scss";
import { motion } from "framer-motion";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Pl from "../../components/Pl/Pl";
import { Modal } from "antd";
import { BsTrash } from "react-icons/bs";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { notification } from "antd";

const Loyalty = () => {
  const { token } = useSelector((state) => state);
  const navigate = useNavigate();
  const [pageContent, setPageContent] = useState([]);
  const [isViewLoyaltyModalOpen, setIsViewLoyaltyModalOpen] = useState(false);
  const [isEditLoyaltyModalOpen, setIsEditLoyaltyModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalCost, setModalCost] = useState(null);
  const [modalDiscount, setModalDiscount] = useState(null);
  const [modalId, setModalId] = useState(null);

  const showViewLoyaltyModal = (title, cost, discount, id) => {
    setModalTitle(title);
    setModalCost(cost)
    setModalDiscount(discount);
    setModalId(id);
    setIsViewLoyaltyModalOpen(true);
  };
  const handleViewLoyaltyModalCancel = () => {
    setIsViewLoyaltyModalOpen(false);
  };
  const showEditLoyaltyModal = () => {
    setIsEditLoyaltyModalOpen(true);
  };
  const handleEditLoyaltyModalCancel = () => {
    setIsEditLoyaltyModalOpen(false);
  };

  const getLoyalty = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST ?? window.location.origin}/NewAdminPanel/settings/getLoyalty`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify({ entity_id: 1 }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      console.log(responseData);
      setPageContent(responseData);
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  useEffect(() => {
    getLoyalty();
  }, []);

  const editLoyalty = async (id) => {
    try {
      const data = {
        ID: id,
        title: modalTitle,
        total_cost: modalCost,
        discount_percentage: modalDiscount
      }

      const response = await fetch(
        `${process.env.REACT_APP_HOST ?? window.location.origin}/NewAdminPanel/settings/editLoyalty`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      notification.success({ message: "Блок лояльности успешно отредактирован" });
      handleEditLoyaltyModalCancel();
      // setModalTitle("");
      // setModalDiscount("");
      // setModalCost("");
      getLoyalty();
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  const handleDeleteLoyalty = async (id) => {
    try {
      const d = new FormData();
      d.append("id", id);

      const response = await fetch(
        `${process.env.REACT_APP_HOST ?? window.location.origin}/NewAdminPanel/settings/deleteLoyalty`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ID: id}),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      notification.success({ message: "Блок лояльности успешно удален" });
      handleViewLoyaltyModalCancel();
      getLoyalty();
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      exit={{ opacity: 0 }}
      className="page"
    >
      <div className="pageBody">
        <div className="ArticlePage__body pageBody-content">
          <div className="ArticlePage__button">
            <Button
              styles={{ marginBottom: "30px" }}
              text={"Добавить"}
              onClick={() => {
                navigate("/add-loyalty");
              }}
            ></Button>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr 1fr",
              gap: "30px",
            }}
          >
            {pageContent
              ? pageContent.map((item) => (
                  <Pl
                    style={{
                      width: "100%",
                      height: "200px",
                      backgroundColor: "#fff",
                    }}
                    text={item.title}
                    onClick={() =>
                      showViewLoyaltyModal(
                        item.title,
                        item.total_cost,
                        item.discount_percentage,
                        item.ID
                      )
                    }
                  ></Pl>
                ))
              : null}
          </div>
          <Modal
            open={isViewLoyaltyModalOpen}
            title={modalTitle}
            onCancel={handleViewLoyaltyModalCancel}
            footer={[]}
            width={580}
          >
             <div className="QAPage__item DeliveryPage__item">
                <div className="QAPage__item-label">Название</div>
                <p style={{textAlign: 'left', marginBottom: '0px'}} className="DeliveryPage__modal-title">{modalTitle}</p>
              </div>
             <div className="QAPage__item DeliveryPage__item">
                <div className="QAPage__item-label">Общая стоимость покупок</div>
                <p style={{textAlign: 'left', marginBottom: '0px'}} className="DeliveryPage__modal-title">{modalCost}</p>
              </div>
             <div className="QAPage__item DeliveryPage__item">
                <div className="QAPage__item-label">Процент скидки</div>
                <p style={{textAlign: 'left', marginBottom: '0px'}} className="DeliveryPage__modal-title">{modalDiscount}</p>
              </div>
            {/* <div className="ArticlePage__modal-text">Статья</div> */}
            <div className="ArticlePage__modal-wrapper">
              <Button
                key="back"
                onClick={() => handleDeleteLoyalty(modalId)}
                text={"Удалить"}
                variant={"danger"}
                before={<BsTrash />}
              />
              <Button
                key="back"
                onClick={showEditLoyaltyModal}
                text={"Редактировать"}
              />
            </div>
            <Modal
              open={isEditLoyaltyModalOpen}
              title="Редактировать"
              onCancel={handleEditLoyaltyModalCancel}
              footer={[]}
              width={880}
            >
              <div className="QAPage__item">
                <div className="QAPage__item-label">Название</div>
                <Input
                  style={{
                    border: "1.5px solid rgba(176, 194, 255, .6)",
                    borderRadius: "13px",
                  }}
                  maskType={String}
                  shadow
                  onChange={(e) => {
                    setModalTitle(e.target.value);
                  }}
                  value={modalTitle}
                />
              </div>
              <div className="QAPage__item">
                <div className="QAPage__item-label">Общая стоимость покупок</div>
                <Input
                  style={{
                    border: "1.5px solid rgba(176, 194, 255, .6)",
                    borderRadius: "13px",
                  }}
                  maskType={Number}
                  shadow
                  onChange={(e) => {
                    setModalCost(e.target.value);
                  }}
                  value={modalCost}
                />
              </div>
              <div className="QAPage__item">
                <div className="QAPage__item-label">Процент скидки</div>
                <Input
                  style={{
                    border: "1.5px solid rgba(176, 194, 255, .6)",
                    borderRadius: "13px",
                  }}
                  maskType={Number}
                  shadow
                  onChange={(e) => {
                    setModalDiscount(e.target.value);
                  }}
                  value={modalDiscount}
                />
              </div>
              <div style={{marginTop: '90px'}} className="ArticlePage__modal-wrapper">
                <Button
                  key="back"
                  onClick={handleEditLoyaltyModalCancel}
                  text={"Отмена"}
                  variant={"danger"}
                />
                <Button
                  key="back"
                  onClick={() => editLoyalty(modalId)}
                  text={"Сохранить"}
                />
              </div>
            </Modal>
          </Modal>
        </div>
      </div>
    </motion.div>
  );
};

export default Loyalty;
