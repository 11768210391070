import { Button, Modal, Upload } from "antd";
import { useEffect, useState } from "react";
import endpoints from "../../services/endpoints";
import checkAuth from "../../services/checkAuth";
import { useSelector } from "react-redux";
import { LoadingOutlined } from "@ant-design/icons";
import { TiPlusOutline } from "react-icons/ti";
import { BiPlus } from "react-icons/bi";
import Input from "../../components/Input/Input";
import SaveIcon from "../../icons/SaveIcon/SaveIcon";
import checkDom from "../../funcs/checkDom";
import "./Banners.scss";
import Btn from "../../components/Button/Button";
import { motion } from "framer-motion";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
} from "react-grid-drag";

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
  });

const asyncRequest = async ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

export const Banners = (props) => {
  const { token, settings } = useSelector((state) => state);
  const [data, setData] = useState([]);
  const [squareBannersData, setSquareBannersData] = useState([]);
  const [rectangularBannersData, setRectangularBannersData] = useState([]);
  const [image, setImage] = useState("");
  const [tempImage, setTempImage] = useState("");
  const [bannerText, setBannerText] = useState("");
  const [editImage, setEditImage] = useState("");
  const [tempEditImage, setTempEditImage] = useState("");
  const [bannerEditText, setBannerEditText] = useState("");
  const [bannerUrl, setBannerUrl] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSquareBannerModalOpen, setIsSquareBannerModalOpen] = useState(false);
  const [isRectangularBannerModalOpen, setIsRectangularBannerModalOpen] =
    useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [localId, setLocalId] = useState("");
  const [localImage, setLocalImage] = useState("");
  const [localText, setLocalText] = useState("");
  const [localUrl, setLocalUrl] = useState("");

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showSquareBannerModal = () => {
    setIsSquareBannerModalOpen(true);
  };
  const showRectangularBannerModal = () => {
    setIsRectangularBannerModalOpen(true);
  };

  const handleEditBanner = (id, image, text, url) => {
    setIsEditModalOpen(true);
    setLocalId(id);
    setLocalImage(image);
    setLocalText(text);
    setLocalUrl(url);
  };
  const handleCancelEditBanner = () => {
    setIsEditModalOpen(false);
  };

  const handleSave = () => {
    addBanner();
    setImage("");
    setTempImage("");
    setBannerText("");
    setBannerUrl("");
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleSquareBannerSave = () => {
    addSquareBanner();
    setImage("");
    setTempImage("");
    setBannerText("");
    setBannerUrl("");
    setIsModalOpen(false);
  };

  const handleSquareBannerCancel = () => {
    setIsSquareBannerModalOpen(false);
    handleCancel();
  };

  const handleRectangularBannerSave = () => {
    addRectangularBanner();
    setImage("");
    setTempImage("");
    setBannerText("");
    setBannerUrl("");
    setIsModalOpen(false);
  };

  const handleRectangularBannerCancel = () => {
    setIsRectangularBannerModalOpen(false);
    handleCancel();
  };

  const fetchData = async () => {
    try {
      let res = await fetch(endpoints.getBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const d = await checkAuth(res);
      setData(d);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchSquareBannersData = async () => {
    try {
      const formData = new FormData();
      formData.append("type", 1);
      let res = await fetch(endpoints.getBanners, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const d = await checkAuth(res);
      setSquareBannersData(d);
    } catch (err) {
      console.log(err);
    }
  };
  const fetchRectangularBannersData = async () => {
    try {
      const formData = new FormData();
      formData.append("type", 0);
      let res = await fetch(endpoints.getBanners, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const d = await checkAuth(res);
      setRectangularBannersData(d);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchData();
    if (
      window.location.origin === 'https://uzgen.gscore.ru' || 
      window.location.origin === 'https://mama.gscore.ru' || 
      window.location.origin === 'https://365meat.gscore.ru' || 
      window.location.origin === 'https://kekeev.gscore.ru' || 
      window.location.origin === 'https://ident.gscore.ru' || 
      window.location.origin === 'https://pizzburg.gscore.ru' || 
      window.location.origin === 'https://mamasochi.gscore.ru' || 
      window.location.origin === 'https://test1.gscore.ru' || 
      window.location.origin === 'https://test.sellkit.ru' || 
      window.location.origin === 'https://test0panel.sellkit.ru' || 
      window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
      window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
      window.location.origin === 'https://mangal-panel.sellkit.ru' || 
      window.location.origin === 'https://brofood-panel.sellkit.ru' || 
      window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
      window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
      window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
      window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
      window.location.origin === 'https://simcity-panel.sellkit.ru' || 
      window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
      window.location.origin === 'https://brofood-panel.sellkit.ru' || 
      window.location.origin === 'https://darvin-panel.sellkit.ru' || 
      window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
      window.location.origin === 'https://papapanda.gscore.ru' || 
      window.location.origin === 'https://paulbaker.gscore.ru'|| 
      window.location.origin === 'https://darvin.gscore.ru'|| 
      window.location.origin === 'https://iiko.gscore.ru'||
      window.location.origin === "https://iiko.gscore.ru" ||
      window.location.origin === "https://jonnytunec.gscore.ru" ||
      window.location.origin === "https://kekeev.gscore.ru" ||
      window.location.origin === "https://pizzeria.gscore.ru" ||
      window.location.origin === 'https://tigrus.gscore.ru' ||
      window.location.origin === "https://osaka.gscore.ru" ||
      window.location.origin === "http://localhost:3000"
    ) {
      fetchSquareBannersData();
      fetchRectangularBannersData();
    }
    if (
      window.location.origin === "https://ibazar.gscore.ru" ||
      window.location.origin === "https://ident.gscore.ru" || 
      window.location.origin === 'https://macarons.gscore.ru'
    ) {
      fetchRectangularBannersData();
    }
  }, []);

  const addBanner = async () => {
    try {
      const formData = new FormData();

      formData.append("width", 250);
      formData.append("height", 150);
      formData.append("image", image);
      if (
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === "https://ibazar.gscore.ru" ||
        window.location.origin === 'https://mama.gscore.ru' || 
        window.location.origin === 'https://365meat.gscore.ru' || 
        window.location.origin === 'https://kekeev.gscore.ru' || 
        window.location.origin === 'https://ident.gscore.ru' || 
        window.location.origin === 'https://pizzburg.gscore.ru' || 
        window.location.origin === 'https://mamasochi.gscore.ru' || 
        window.location.origin === 'https://test1.gscore.ru' || 
        window.location.origin === 'https://test.sellkit.ru' || 
        window.location.origin === 'https://test0panel.sellkit.ru' || 
        window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://mangal-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
        window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
        window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
        window.location.origin === 'https://simcity-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://darvin-panel.sellkit.ru' || 
        window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda.gscore.ru' || 
        window.location.origin === 'https://paulbaker.gscore.ru'|| 
        window.location.origin === 'https://darvin.gscore.ru'|| 
        window.location.origin === 'https://iiko.gscore.ru'||
        window.location.origin === "https://iiko.gscore.ru" ||
        window.location.origin === "https://jonnytunec.gscore.ru" ||
        window.location.origin === "https://kekeev.gscore.ru" ||
        window.location.origin === "https://pizzeria.gscore.ru" ||
        window.location.origin === 'https://tigrus.gscore.ru' ||
        window.location.origin === "https://osaka.gscore.ru" ||
        window.location.origin === "http://localhost:3000"
      ) {
        formData.append("text", bannerText);
      }

      let res = await fetch(endpoints.addBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
    } catch (err) {
      console.log(err);
    }
    fetchData();
  };

  const addSquareBanner = async () => {
    try {
      const formData = new FormData();

      formData.append("width", 250);
      formData.append("height", 150);
      formData.append("image", image);
      formData.append("type", 1);
      if (
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === "https://ibazar.gscore.ru" ||
        window.location.origin === 'https://mama.gscore.ru' || 
        window.location.origin === 'https://365meat.gscore.ru' || 
        window.location.origin === 'https://kekeev.gscore.ru' || 
        window.location.origin === 'https://ident.gscore.ru' || 
        window.location.origin === 'https://pizzburg.gscore.ru' || 
        window.location.origin === 'https://mamasochi.gscore.ru' || 
        window.location.origin === 'https://test1.gscore.ru' || 
        window.location.origin === 'https://test.sellkit.ru' || 
        window.location.origin === 'https://test0panel.sellkit.ru' || 
        window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://mangal-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
        window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
        window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
        window.location.origin === 'https://simcity-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://darvin-panel.sellkit.ru' || 
        window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda.gscore.ru' || 
        window.location.origin === 'https://paulbaker.gscore.ru'|| 
        window.location.origin === 'https://darvin.gscore.ru'|| 
        window.location.origin === 'https://iiko.gscore.ru'||
        window.location.origin === "https://jonnytunec.gscore.ru" ||
        window.location.origin === "https://kekeev.gscore.ru" ||
        window.location.origin === "https://pizzeria.gscore.ru" ||
        window.location.origin === 'https://tigrus.gscore.ru' ||
        window.location.origin === "https://osaka.gscore.ru" ||
        window.location.origin === "https://ident.gscore.ru" ||
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === "http://localhost:3000"
      ) {
        formData.append("text", bannerText);
      }
      if (
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === 'https://mama.gscore.ru' || 
        window.location.origin === 'https://365meat.gscore.ru' || 
        window.location.origin === 'https://kekeev.gscore.ru' || 
        window.location.origin === 'https://ident.gscore.ru' || 
        window.location.origin === 'https://pizzburg.gscore.ru' || 
        window.location.origin === 'https://mamasochi.gscore.ru' || 
        window.location.origin === 'https://test1.gscore.ru' || 
        window.location.origin === 'https://test.sellkit.ru' || 
        window.location.origin === 'https://test0panel.sellkit.ru' || 
        window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://mangal-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
        window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
        window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
        window.location.origin === 'https://simcity-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://darvin-panel.sellkit.ru' || 
        window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda.gscore.ru' || 
        window.location.origin === 'https://paulbaker.gscore.ru'|| 
        window.location.origin === 'https://darvin.gscore.ru'|| 
        window.location.origin === 'https://iiko.gscore.ru'||
        window.location.origin === "https://iiko.gscore.ru" ||
        window.location.origin === "https://jonnytunec.gscore.ru" ||
        window.location.origin === "https://kekeev.gscore.ru" ||
        window.location.origin === "https://pizzeria.gscore.ru" ||
        window.location.origin === 'https://tigrus.gscore.ru' ||
        window.location.origin === "https://osaka.gscore.ru" ||
        window.location.origin === "http://localhost:3000"
      ) {
        formData.append("url", bannerUrl);
      }

      let res = await fetch(endpoints.addBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
    } catch (err) {
      console.log(err);
    }
    fetchSquareBannersData();
  };
  const addRectangularBanner = async () => {
    try {
      const formData = new FormData();

      formData.append("width", 250);
      formData.append("height", 150);
      formData.append("image", image);
      if (
        window.location.origin === "https://ibazar.gscore.ru" ||
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === "https://ident.gscore.ru" ||
        window.location.origin === "https://kekeev.gscore.ru" ||
        window.location.origin === 'https://mama.gscore.ru' ||
        window.location.origin === 'https://365meat.gscore.ru' ||
        window.location.origin === 'https://pizzburg.gscore.ru' ||
        window.location.origin === 'https://mamasochi.gscore.ru' ||
        window.location.origin === 'https://test1.gscore.ru' ||
        window.location.origin === 'https://test.sellkit.ru' ||
        window.location.origin === 'https://test0panel.sellkit.ru' ||
        window.location.origin === 'https://shtefan-panel.sellkit.ru' ||
        window.location.origin === 'https://zafferano-panel.sellkit.ru' ||
        window.location.origin === 'https://mangal-panel.sellkit.ru' ||
        window.location.origin === 'https://brofood-panel.sellkit.ru' ||
        window.location.origin === 'https://mamasochi-panel.sellkit.ru' ||
        window.location.origin === 'https://papapanda-panel.sellkit.ru' ||
        window.location.origin === 'https://aroundfire-panel.sellkit.ru' ||
        window.location.origin === 'https://foodbowl-panel.sellkit.ru' ||
        window.location.origin === 'https://simcity-panel.sellkit.ru' ||
        window.location.origin === 'https://zafferano-panel.sellkit.ru' ||
        window.location.origin === 'https://brofood-panel.sellkit.ru' ||
        window.location.origin === 'https://darvin-panel.sellkit.ru' ||
        window.location.origin === 'https://shaverno-panel.sellkit.ru' ||
        window.location.origin === 'https://papapanda.gscore.ru' ||
         window.location.origin === 'https://paulbaker.gscore.ru'|| 
         window.location.origin === 'https://darvin.gscore.ru'|| 
         window.location.origin === 'https://iiko.gscore.ru'||
        window.location.origin === "https://iiko.gscore.ru" ||
        window.location.origin === "https://jonnytunec.gscore.ru" ||
        window.location.origin === "https://pizzeria.gscore.ru" ||
        window.location.origin === 'https://tigrus.gscore.ru' ||
        window.location.origin === "https://osaka.gscore.ru" ||
        window.location.origin === "http://localhost:3000"
      ) {
        formData.append("text", bannerText);
      }
      if (
        window.location.origin === "https://ibazar.gscore.ru" ||
        window.location.origin === "https://ident.gscore.ru" ||
        window.location.origin === "https://macarons.gscore.ru"
      ) {
        formData.append("url", bannerUrl);
      }
      if (
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === 'https://mama.gscore.ru' || 
        window.location.origin === 'https://365meat.gscore.ru' || 
        window.location.origin === 'https://kekeev.gscore.ru' || 
        window.location.origin === 'https://ident.gscore.ru' || 
        window.location.origin === 'https://pizzburg.gscore.ru' || 
        window.location.origin === 'https://mamasochi.gscore.ru' || 
        window.location.origin === 'https://test1.gscore.ru' || 
        window.location.origin === 'https://test.sellkit.ru' || 
        window.location.origin === 'https://test0panel.sellkit.ru' || 
        window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://mangal-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
        window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
        window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
        window.location.origin === 'https://simcity-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://darvin-panel.sellkit.ru' || 
        window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda.gscore.ru' || 
        window.location.origin === 'https://paulbaker.gscore.ru'||
        window.location.origin === 'https://darvin.gscore.ru'||
        window.location.origin === 'https://iiko.gscore.ru'||
        window.location.origin === "https://iiko.gscore.ru" ||
        window.location.origin === "https://jonnytunec.gscore.ru" ||
        window.location.origin === "https://kekeev.gscore.ru" ||
        window.location.origin === "https://pizzeria.gscore.ru" ||
        window.location.origin === 'https://tigrus.gscore.ru' ||
        window.location.origin === "https://osaka.gscore.ru" ||
        window.location.origin === "http://localhost:3000"
      ) {
        formData.append("type", 0);
        formData.append("url", bannerUrl);
      }

      let res = await fetch(endpoints.addBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
    } catch (err) {
      console.log(err);
    }
    fetchRectangularBannersData();
  };

  const editBanner = async (id) => {
    try {
      const formData = new FormData();

      formData.append("width", 250);
      formData.append("height", 150);
      formData.append("image", editImage);
      formData.append("text", bannerEditText);

      let res = await fetch(endpoints.addBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
      fetchData();
    } catch (err) {
      console.log(err);
    }
  };

  const editMamaBanner = async (id) => {
    try {
      const formData = new FormData();

      formData.append("ID", id);
      if (editImage) {
        formData.append("image", editImage);
      }
      if (
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === "https://ident.gscore.ru" ||
        window.location.origin === "https://kekeev.gscore.ru" ||
        window.location.origin === "https://ibazar.gscore.ru"
      ) {
        formData.append("text", localText);
      }
      if (
        window.location.origin === "https://uzgen.gscore.ru" ||
        window.location.origin === "http://localhost:3000" ||
        window.location.origin === 'https://mama.gscore.ru' || 
        window.location.origin === 'https://365meat.gscore.ru' || 
        window.location.origin === 'https://kekeev.gscore.ru' || 
        window.location.origin === 'https://ident.gscore.ru' || 
        window.location.origin === 'https://pizzburg.gscore.ru' || 
        window.location.origin === 'https://mamasochi.gscore.ru' || 
        window.location.origin === 'https://test1.gscore.ru' || 
        window.location.origin === 'https://test.sellkit.ru' || 
        window.location.origin === 'https://test0panel.sellkit.ru' || 
        window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://mangal-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
        window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
        window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
        window.location.origin === 'https://simcity-panel.sellkit.ru' || 
        window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
        window.location.origin === 'https://brofood-panel.sellkit.ru' || 
        window.location.origin === 'https://darvin-panel.sellkit.ru' || 
        window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
        window.location.origin === 'https://papapanda.gscore.ru' || 
        window.location.origin === 'https://paulbaker.gscore.ru'|| 
        window.location.origin === 'https://darvin.gscore.ru'|| 
        window.location.origin === 'https://iiko.gscore.ru'
      ) {
        formData.append("text", localText);
        formData.append("url", localUrl);
      }
      if (window.location.origin === 'https://macarons.gscore.ru') {
        formData.append("url", localUrl)
      }

      let res = await fetch(endpoints.editBanner, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
      handleCancelEditBanner();
    } catch (err) {
      console.log(err);
    }
    fetchSquareBannersData();
    fetchRectangularBannersData();
  };

  const handleUpload = async (info) => {
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const base64 = await toBase64(info.file.originFileObj);
      const blobImg = URL.createObjectURL(info.file.originFileObj);
      setTempImage(blobImg);
      setImage(base64);
    }
  };
  const handleEditUpload = async (info) => {
    console.log(info.file.status);
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const base64 = await toBase64(info.file.originFileObj);
      const blobImg = URL.createObjectURL(info.file.originFileObj);
      setTempEditImage(blobImg);
      setEditImage(base64);
    }
  };

  const handleDelete = async (id) => {
    try {
      const formData = new FormData();
      formData.append("ID", id);

      let res = await fetch(endpoints.removeBanners, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });

      const d = await checkAuth(res);
      fetchData();
      fetchSquareBannersData();
      fetchRectangularBannersData();
    } catch (err) {
      console.log(err);
    }
  };

  const handleSquareBannerOrderChange = (
    sourceId,
    sourceIndex,
    targetIndex,
    targetId
  ) => {
    if (sourceIndex === squareBannersData.length) {
      return;
    } else {
      const nextState = swap(squareBannersData, sourceIndex, targetIndex);
      setSquareBannersData(nextState);
    }
  };
  const handleRectangularBannerOrderChange = (
    sourceId,
    sourceIndex,
    targetIndex,
    targetId
  ) => {
    if (sourceIndex === rectangularBannersData.length) {
      return;
    } else {
      const nextState = swap(rectangularBannersData, sourceIndex, targetIndex);
      setRectangularBannersData(nextState);
    }
  };
  const handleDataBannerOrderChange = (
    sourceId,
    sourceIndex,
    targetIndex,
    targetId
  ) => {
    if (sourceIndex === data.length) {
      return;
    } else {
      const nextState = swap(data, sourceIndex, targetIndex);
      setData(nextState);
    }
  };

  useEffect(() => {
    setEditImage()
  }, [isEditModalOpen])

  if (
    window.location.origin === "https://uzgen.gscore.ru" ||
    window.location.origin === 'https://mama.gscore.ru' || 
    window.location.origin === 'https://365meat.gscore.ru' || 
    window.location.origin === 'https://kekeev.gscore.ru' || 
    window.location.origin === 'https://pizzburg.gscore.ru' || 
    window.location.origin === 'https://mamasochi.gscore.ru' || 
    window.location.origin === 'https://test1.gscore.ru' || 
    window.location.origin === 'https://test.sellkit.ru' || 
    window.location.origin === 'https://test0panel.sellkit.ru' || 
    window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
    window.location.origin === 'https://mangal-panel.sellkit.ru' || 
    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
    window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
    window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
    window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
    window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
    window.location.origin === 'https://simcity-panel.sellkit.ru' || 
    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
    window.location.origin === 'https://darvin-panel.sellkit.ru' || 
    window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
    window.location.origin === 'https://papapanda.gscore.ru' || 
    window.location.origin === 'https://paulbaker.gscore.ru'|| 
    window.location.origin === 'https://darvin.gscore.ru'|| 
    window.location.origin === 'https://iiko.gscore.ru'||
    window.location.origin === "https://iiko.gscore.ru" ||
    window.location.origin === "https://jonnytunec.gscore.ru/" ||
    window.location.origin === "https://kekeev.gscore.ru" ||
    window.location.origin === "https://pizzeria.gscore.ru" ||
    window.location.origin === 'https://tigrus.gscore.ru' ||
    window.location.origin === "https://osaka.gscore.ru" ||
    window.location.origin === "https://ibazar.gscore.ru" ||
    window.location.origin === "https://ident.gscore.ru" ||
    window.location.origin === 'https://uzgen.gscore.ru' ||
    window.location.origin === 'https://macarons.gscore.ru' ||
    window.location.origin === "http://localhost:3000"
  ) {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        exit={{ opacity: 0 }}
        className="page"
      >
        <div className="pageBody">
          <div className="pageBody-content">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "50px",
              }}
            >
              {window.location.origin === "https://ibazar.gscore.ru" || 
              window.location.origin === 'https://uzgen.gscore.ru' || 
              window.location.origin === 'https://kekeev.gscore.ru' || 
              window.location.origin === 'https://ident.gscore.ru' ? null : (
                <Btn
                  styles={{ marginRight: "20px" }}
                  text={"Квадратные баннеры"}
                  onClick={showSquareBannerModal}
                ></Btn>
              )}
              <Modal
                open={isSquareBannerModalOpen}
                onCancel={handleSquareBannerCancel}
                footer={ <Button
                  onClick={handleSquareBannerCancel}
                  danger
                  type={"primary"}
                  style={{ marginLeft: "auto", display: "block" }}
                >
                  Закрыть
                </Button>}
                width={1200}
                style={{paddingRight:'20px'}}
              >
                <h2>Редактировать квадратные баннеры</h2>
                  <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <GridContextProvider onChange={handleSquareBannerOrderChange}>
                      <GridDropZone
                        boxesPerRow={4}
                        rowHeight={350}
                        style={{
                          // height: squareBannersData.length > 0 ? "1000px" : "200px",
                          width: '100%',
                          height: '70vh',
                          backgroundColor: 'white',
                        }}
                      >
                        {squareBannersData?.map((c, i) => (
                          <GridItem key={i}>
                            <div
                              style={{
                                // width: c.width,
                                height: '250px',
                                display: "block",
                              }}
                            >
                              <div
                                style={{
                                  border: "2px solid gray",
                                  // width: c.width,
                                  // height: c.height,
                                  width: '250px',
                                  height: '100%',
                                  padding: 12,
                                  borderRadius: "12px",
                                  position: "relative",
                                  margin: 12,
                                  pointerEvents: "none",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: -12,
                                    cursor: "pointer",
                                    right: -12,
                                    background: "#DC143C",
                                    height: 26,
                                    width: 26,
                                    borderRadius: "50%",
                                    color: "white",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    lineHeight: "24px",
                                    pointerEvents: "auto",
                                  }}
                                  onClick={() => handleDelete(c.ID)}
                                >
                                  x
                                </div>
                                <img src={`${c.image}`} width={'100%'} height={'100%'} style={{objectFit: 'cover'}} />
                              </div>
                              <Btn
                                styles={{ width: "95%" }}
                                justify={"center"}
                                text={"Редактировать"}
                                onClick={() =>
                                  handleEditBanner(c.ID, c.image, c.text, c.url)
                                }
                              ></Btn>
                            </div>
                          </GridItem>
                        ))}
                        <GridItem>
                          <button
                            onClick={showModal}
                            style={{
                              height: "350px",
                              border: "1px dashed #aaa",
                              background: "none",
                              outline: "none",
                              cursor: "pointer",
                              backgroundColor: "#eee",
                              padding: "90px 50px 140px",
                            }}
                            type="button"
                          >
                            <BiPlus size={"24px"} color={"#7B99FF"} />
                            <div style={{ color: "#7B99FF", fontWeight: 600 }}>
                              Добавить баннер
                            </div>
                          </button>
                        </GridItem>
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                <Modal
                  title="Редактировать баннер"
                  open={isEditModalOpen}
                  onCancel={handleCancelEditBanner}
                  width={480}
                  footer={[
                    <button
                      type={"button"}
                      onClick={() => editMamaBanner(localId)}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(123, 153, 255)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Сохранить
                    </button>,
                    <button
                      type={"button"}
                      onClick={handleCancelEditBanner}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(255, 77, 79)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                      }}
                    >
                      Отмена
                    </button>,
                  ]}
                >
                  {editImage || localImage ? (
                    <div className="banners__pic-item">
                      <img src={editImage || localImage} alt="" />
                      <div className="baners__pic-item--backdrop">
                        <Upload
                          className="baners__pic-item--new"
                          showUploadList={false}
                          customRequest={asyncRequest}
                          listType="picture-card"
                          onChange={handleEditUpload}
                        >
                          <p>Загрузить другую картинку</p>
                        </Upload>
                      </div>
                    </div>
                  ) : (
                    <Upload
                      showUploadList={false}
                      customRequest={asyncRequest}
                      listType="picture-card"
                      onChange={handleEditUpload}
                    >
                      <p
                        style={{
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Загрузить картинку
                      </p>
                    </Upload>
                  )}
                  {window.location.origin === "https://uzgen.gscore.ru" || 
                  window.location.origin === 'https://mama.gscore.ru' || 
                  window.location.origin === 'https://365meat.gscore.ru' || 
                  window.location.origin === 'https://kekeev.gscore.ru' || 
                  window.location.origin === 'https://pizzburg.gscore.ru' || 
                  window.location.origin === 'https://mamasochi.gscore.ru' || 
                  window.location.origin === 'https://test1.gscore.ru' || 
                  window.location.origin === 'https://test.sellkit.ru' || 
                  window.location.origin === 'https://test0panel.sellkit.ru' || 
                  window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
                  window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                  window.location.origin === 'https://mangal-panel.sellkit.ru' || 
                  window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                  window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
                  window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
                  window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
                  window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
                  window.location.origin === 'https://simcity-panel.sellkit.ru' || 
                  window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                  window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                  window.location.origin === 'https://darvin-panel.sellkit.ru' || 
                  window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
                  window.location.origin === 'https://papapanda.gscore.ru' || 
                  window.location.origin === 'https://paulbaker.gscore.ru'|| 
                  window.location.origin === 'https://darvin.gscore.ru'|| 
                  window.location.origin === 'https://iiko.gscore.ru'||
                  window.location.origin === 'https://macarons.gscore.ru' ||
                  window.location.origin === "https://ident.gscore.ru" ||
                  window.location.origin === "https://ibazar.gscore.ru" ||
                  window.location.origin === "https://kekeev.gscore.ru" ||
                  window.location.origin === "https://uzgen.gscore.ru" ||
                  window.location.origin === "http://localhost:3000" ? (
                    <Input
                      shadow={true}
                      maskType={String}
                      value={localText}
                      onChange={(e) => setLocalText(e.target.value)}
                      placeholder="Название"
                      style={{ marginTop: "20px" }}
                    />
                  ) : null}
                  { window.location.origin === "https://uzgen.gscore.ru" || 
                  window.location.origin === 'https://mama.gscore.ru' || 
                  window.location.origin === 'https://365meat.gscore.ru' || 
                  window.location.origin === 'https://kekeev.gscore.ru' || 
                  window.location.origin === 'https://ident.gscore.ru' || 
                  window.location.origin === 'https://pizzburg.gscore.ru' || 
                  window.location.origin === 'https://mamasochi.gscore.ru' || 
                  window.location.origin === 'https://test1.gscore.ru' || 
                  window.location.origin === 'https://test.sellkit.ru' || 
                  window.location.origin === 'https://test0panel.sellkit.ru' || 
                  window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
                  window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                  window.location.origin === 'https://mangal-panel.sellkit.ru' || 
                  window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                  window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
                  window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
                  window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
                  window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
                  window.location.origin === 'https://simcity-panel.sellkit.ru' || 
                  window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                  window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                  window.location.origin === 'https://darvin-panel.sellkit.ru' || 
                  window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
                  window.location.origin === 'https://papapanda.gscore.ru' || 
                  window.location.origin === 'https://paulbaker.gscore.ru'|| 
                  window.location.origin === 'https://darvin.gscore.ru'|| 
                  window.location.origin === 'https://macarons.gscore.ru' ||
                  window.location.origin === 'https://iiko.gscore.ru'||
                  window.location.origin === "http://localhost:3000" ? (
                    <Input
                      shadow={true}
                      maskType={String}
                      value={localUrl}
                      onChange={(e) => setLocalUrl(e.target.value)}
                      placeholder="URL"
                      style={{ marginTop: "20px" }}
                    />
                  ) : null}
                </Modal>
                <Modal
                    title="Добавить квадратный баннер"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    width={480}
                    style={{maxHeight: '10vh'}}
                    footer={[
                      <button
                        type={"button"}
                        onClick={handleSquareBannerSave}
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(123, 153, 255)",
                          border: "none",
                          color: "#fff",
                          fontWeight: "600",
                          padding: "7px 0",
                          cursor: "pointer",
                          marginBottom: "10px",
                        }}
                      >
                        Сохранить
                      </button>,
                      <button
                        type={"button"}
                        onClick={handleCancel}
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(255, 77, 79)",
                          border: "none",
                          color: "#fff",
                          fontWeight: "600",
                          padding: "7px 0",
                          cursor: "pointer",
                        }}
                      >
                        Отмена
                      </button>,
                    ]}
                  >
                    {tempImage ? (
                      <div className="banners__pic-item">
                        <img src={tempImage} alt="" />
                        <div className="baners__pic-item--backdrop">
                          <Upload
                            className="baners__pic-item--new"
                            showUploadList={false}
                            customRequest={asyncRequest}
                            listType="picture-card"
                            onChange={handleUpload}
                          >
                            <p>Загрузить другую картинку</p>
                          </Upload>
                        </div>
                      </div>
                    ) : (
                      <Upload
                        showUploadList={false}
                        customRequest={asyncRequest}
                        listType="picture-card"
                        onChange={handleUpload}
                      >
                        <p
                          style={{
                            height: "150px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Загрузить картинку
                        </p>
                      </Upload>
                    )}
                    {window.location.origin === "https://uzgen.gscore.ru" || 
                    window.location.origin === "https://ibazar.gscore.ru" ||
                    window.location.origin === 'https://mama.gscore.ru' || 
                    window.location.origin === 'https://365meat.gscore.ru' || 
                    window.location.origin === 'https://kekeev.gscore.ru' || 
                    window.location.origin === 'https://pizzburg.gscore.ru' || 
                    window.location.origin === 'https://mamasochi.gscore.ru' || 
                    window.location.origin === 'https://test1.gscore.ru' || 
                    window.location.origin === 'https://test.sellkit.ru' || 
                    window.location.origin === 'https://test0panel.sellkit.ru' || 
                    window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://mangal-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
                    window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
                    window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
                    window.location.origin === 'https://simcity-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://darvin-panel.sellkit.ru' || 
                    window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda.gscore.ru' || 
                    window.location.origin === 'https://paulbaker.gscore.ru'|| 
                    window.location.origin === 'https://darvin.gscore.ru'|| 
                    window.location.origin === 'https://iiko.gscore.ru'||
                    window.location.origin === 'https://macarons.gscore.ru' ||
                    window.location.origin === "https://iiko.gscore.ru" ||
                    window.location.origin === "https://jonnytunec.gscore.ru/" ||
                    window.location.origin === "https://pizzeria.gscore.ru" ||
                    window.location.origin === 'https://tigrus.gscore.ru' ||
                    window.location.origin === "https://osaka.gscore.ru" ||
                    window.location.origin === "https://ident.gscore.ru" ||
                    window.location.origin === "https://uzgen.gscore.ru" ||
                    window.location.origin === "https://kekeev.gscore.ru" ||
                    window.location.origin === "http://localhost:3000" ? (
                      <>
                        <Input
                          shadow={true}
                          maskType={String}
                          value={bannerText}
                          onChange={(e) => setBannerText(e.target.value)}
                          placeholder="Название"
                          style={{ marginTop: "20px" }}
                        />
                      </>
                    ) : null}
                    {window.location.origin === "https://ibazar.gscore.ru" ||
                     window.location.origin === "https://uzgen.gscore.ru" ||
                    window.location.origin === 'https://mama.gscore.ru' || 
                    window.location.origin === 'https://365meat.gscore.ru' || 
                    window.location.origin === 'https://kekeev.gscore.ru' || 
                    window.location.origin === 'https://ident.gscore.ru' || 
                    window.location.origin === 'https://pizzburg.gscore.ru' || 
                    window.location.origin === 'https://mamasochi.gscore.ru' || 
                    window.location.origin === 'https://test1.gscore.ru' || 
                    window.location.origin === 'https://test.sellkit.ru' || 
                    window.location.origin === 'https://test0panel.sellkit.ru' || 
                    window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://mangal-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
                    window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
                    window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
                    window.location.origin === 'https://simcity-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://darvin-panel.sellkit.ru' || 
                    window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda.gscore.ru' || 
                    window.location.origin === 'https://paulbaker.gscore.ru'||
                    window.location.origin === 'https://darvin.gscore.ru'||
                     window.location.origin === 'https://iiko.gscore.ru'||
                     window.location.origin === 'https://macarons.gscore.ru' ||
                    window.location.origin === "https://iiko.gscore.ru" ||
                    window.location.origin === "https://jonnytunec.gscore.ru/" ||
                    window.location.origin === "https://kekeev.gscore.ru" ||
                    window.location.origin === "https://pizzeria.gscore.ru" ||
                    window.location.origin === 'https://tigrus.gscore.ru' ||
                    window.location.origin === "https://osaka.gscore.ru" ||
                    window.location.origin === "http://localhost:3000" ? (
                      <Input
                        shadow={true}
                        maskType={String}
                        value={bannerUrl}
                        onChange={(e) => setBannerUrl(e.target.value)}
                        placeholder="URL"
                        style={{ marginTop: "20px" }}
                      />
                    ) : null}
                </Modal>
              </Modal>

              <Btn
                styles={{ display: "inline-flex" }}
                text={"Прямоугольные баннеры"}
                onClick={showRectangularBannerModal}
              ></Btn>
              <Modal
                open={isRectangularBannerModalOpen}
                onCancel={handleRectangularBannerCancel}
                footer={
                <Button
                  onClick={handleRectangularBannerCancel}
                  danger
                  type={"primary"}
                  style={{ marginLeft: "auto", display: "block" }}
                >
                  Закрыть
                </Button>
                }
                width={1200}
              >
                <h2>Редактировать прямоугольные баннеры</h2>
                  <div style={{overflowY: 'auto', overflowX: 'hidden', width: '100%', height: '100%'}}>
                    <GridContextProvider
                      onChange={handleRectangularBannerOrderChange}
                    >
                      <GridDropZone
                        boxesPerRow={4}
                        rowHeight={250}
                        style={{
                          width: '100%',
                          height: '70vh',
                          backgroundColor: 'white',
                        }}
                      >
                        {rectangularBannersData?.map((c, i) => (
                          <GridItem key={i}>
                            <div
                              style={{
                                // width: c.width,
                                // height: c.height,
                                display: "block",
                              }}
                            >
                              <div
                                style={{
                                  border: "2px solid gray",
                                  // width: c.width,
                                  height: 150,
                                  padding: 12,
                                  borderRadius: "12px",
                                  position: "relative",
                                  margin: 12,
                                  pointerEvents: "none",
                                }}
                              >
                                <div
                                  style={{
                                    position: "absolute",
                                    top: -12,
                                    cursor: "pointer",
                                    right: -12,
                                    background: "#DC143C",
                                    height: 26,
                                    width: 26,
                                    borderRadius: "50%",
                                    color: "white",
                                    fontWeight: 500,
                                    textAlign: "center",
                                    lineHeight: "24px",
                                    pointerEvents: "auto",
                                  }}
                                  onClick={() => handleDelete(c.ID)}
                                >
                                  x
                                </div>
                                <img src={`${c.image}`} width={'100%'} height={'100%'} style={{objectFit: 'cover'}}/>
                              </div>
                              <Btn
                                styles={{ width: "95%" }}
                                justify={"center"}
                                text={"Редактировать"}
                                onClick={() =>
                                  handleEditBanner(c.ID, c.image, c.text, c.url)
                                }
                              ></Btn>
                            </div>
                          </GridItem>
                        ))}
                        <GridItem>
                          <button
                            onClick={showModal}
                            style={{
                              height: "150px",
                              border: "1px dashed #aaa",
                              background: "none",
                              outline: "none",
                              cursor: "pointer",
                              backgroundColor: "#eee",
                              padding: "40px 50px 60px",
                            }}
                            type="button"
                          >
                            <BiPlus size={"24px"} color={"#7B99FF"} />
                            <div style={{ color: "#7B99FF", fontWeight: 600 }}>
                              Добавить баннер
                            </div>
                          </button>
                        </GridItem>
                      </GridDropZone>
                    </GridContextProvider>
                  </div>
                <Modal
                  title="Редактировать баннер"
                  open={isEditModalOpen}
                  onCancel={handleCancelEditBanner}
                  width={480}
                  footer={[
                    <button
                      type={"button"}
                      onClick={() => editMamaBanner(localId)}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(123, 153, 255)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                        marginBottom: "10px",
                      }}
                    >
                      Сохранить
                    </button>,
                    <button
                      type={"button"}
                      onClick={handleCancelEditBanner}
                      style={{
                        width: "100%",
                        backgroundColor: "rgb(255, 77, 79)",
                        border: "none",
                        color: "#fff",
                        fontWeight: "600",
                        padding: "7px 0",
                        cursor: "pointer",
                      }}
                    >
                      Отмена
                    </button>,
                  ]}
                >
                  {editImage || localImage ? (
                    <div className="banners__pic-item">
                      <img src={editImage || localImage} alt="" />
                      <div className="baners__pic-item--backdrop">
                         <Upload
                           className="baners__pic-item--new"
                           showUploadList={false}
                           customRequest={asyncRequest}
                           listType="picture-card"
                           onChange={handleEditUpload}
                         >
                           <p>Загрузить другую картинку</p>
                         </Upload>
                      </div>
                    </div>
                  ) : (
                    <Upload
                      showUploadList={false}
                      customRequest={asyncRequest}
                      listType="picture-card"
                      onChange={handleEditUpload}
                    >
                      <p
                        style={{
                          height: "150px",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Загрузить картинку
                      </p>
                    </Upload>
                  )}
                  {window.location.origin === 'https://mama.gscore.ru' || 
                  window.location.origin === 'https://pizzburg.gscore.ru' || 
                  window.location.origin === 'https://365meat.gscore.ru' || 
                  window.location.origin === 'https://kekeev.gscore.ru' || 
                  window.location.origin === 'https://ident.gscore.ru' || 
                  window.location.origin === 'https://mamasochi.gscore.ru' || 
                  window.location.origin === "https://test1.gscore.ru" || 
                  window.location.origin === "https://test.sellkit.ru" || 
                  window.location.origin === "https://test0panel.sellkit.ru" || 
                  window.location.origin === "https://shtefan-panel.sellkit.ru" || 
                  window.location.origin === "https://zafferano-panel.sellkit.ru" || 
                  window.location.origin === "https://mangal-panel.sellkit.ru" || 
                  window.location.origin === "https://brofood-panel.sellkit.ru" || 
                  window.location.origin === "https://mamasochi-panel.sellkit.ru" || 
                  window.location.origin === "https://papapanda-panel.sellkit.ru" || 
                  window.location.origin === "https://aroundfire-panel.sellkit.ru" || 
                  window.location.origin === "https://foodbowl-panel.sellkit.ru" || 
                  window.location.origin === "https://simcity-panel.sellkit.ru" || 
                  window.location.origin === "https://zafferano-panel.sellkit.ru" || 
                  window.location.origin === "https://brofood-panel.sellkit.ru" || 
                  window.location.origin === "https://darvin-panel.sellkit.ru" || 
                  window.location.origin === "https://shaverno-panel.sellkit.ru" || 
                  window.location.origin === "https://papapanda.gscore.ru" || 
                  window.location.origin === "https://uzgen.gscore.ru" || 
                  window.location.origin === 'https://paulbaker.gscore.ru'|| 
                  window.location.origin === 'https://darvin.gscore.ru'|| 
                  window.location.origin === 'https://macarons.gscore.ru' ||
                  window.location.origin === 'https://iiko.gscore.ru'||
                  window.location.origin === "https://ident.gscore.ru" ||
                  window.location.origin === "https://ibazar.gscore.ru" ||
                  window.location.origin === "https://kekeev.gscore.ru" ||
                  window.location.origin === "https://uzgen.gscore.ru" ||
                  window.location.origin === "http://localhost:3000" ? (
                    <Input
                      shadow={true}
                      maskType={String}
                      value={localText}
                      onChange={(e) => setLocalText(e.target.value)}
                      placeholder="Название"
                      style={{ marginTop: "20px" }}
                    />
                  ) : null}
                  {window.location.origin === "https://uzgen.gscore.ru" || 
                  window.location.origin === 'https://mama.gscore.ru' || 
                  window.location.origin === 'https://365meat.gscore.ru' || 
                  window.location.origin === 'https://kekeev.gscore.ru' || 
                  window.location.origin === 'https://ident.gscore.ru' || 
                  window.location.origin === 'https://pizzburg.gscore.ru' || 
                  window.location.origin === 'https://mamasochi.gscore.ru' || 
                  window.location.origin === 'https://test1.gscore.ru' || 
                  window.location.origin === 'https://test.sellkit.ru' || 
                  window.location.origin === 'https://test0panel.sellkit.ru' || 
                  window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
                  window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                  window.location.origin === 'https://mangal-panel.sellkit.ru' || 
                  window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                  window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
                  window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
                  window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
                  window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
                  window.location.origin === 'https://simcity-panel.sellkit.ru' || 
                  window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                  window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                  window.location.origin === 'https://darvin-panel.sellkit.ru' || 
                  window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
                  window.location.origin === 'https://papapanda.gscore.ru' || 
                  window.location.origin === 'https://paulbaker.gscore.ru'||
                  window.location.origin === 'https://darvin.gscore.ru'||
                   window.location.origin === 'https://iiko.gscore.ru'|| 
                   window.location.origin === 'https://macarons.gscore.ru' ||
                  window.location.origin === "http://localhost:3000"  ? (
                    <Input
                      shadow={true}
                      maskType={String}
                      value={localUrl}
                      onChange={(e) => setLocalUrl(e.target.value)}
                      placeholder="URL"
                      style={{ marginTop: "20px" }}
                    />
                  ) : null}
                </Modal>
                <Modal
                    title="Добавить прямоугольный баннер"
                    open={isModalOpen}
                    onCancel={handleCancel}
                    width={480}
                    footer={[
                      <button
                        type={"button"}
                        onClick={handleRectangularBannerSave}
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(123, 153, 255)",
                          border: "none",
                          color: "#fff",
                          fontWeight: "600",
                          padding: "7px 0",
                          cursor: "pointer",
                          marginBottom: "10px",
                        }}
                      >
                        Сохранить
                      </button>,
                      <button
                        type={"button"}
                        onClick={handleCancel}
                        style={{
                          width: "100%",
                          backgroundColor: "rgb(255, 77, 79)",
                          border: "none",
                          color: "#fff",
                          fontWeight: "600",
                          padding: "7px 0",
                          cursor: "pointer",
                        }}
                      >
                        Отмена
                      </button>,
                    ]}
                  >
                    {tempImage ? (
                      <div className="banners__pic-item">
                        <img src={tempImage} alt="" />
                        <div className="baners__pic-item--backdrop">
                          <Upload
                            className="baners__pic-item--new"
                            showUploadList={false}
                            customRequest={asyncRequest}
                            listType="picture-card"
                            onChange={handleUpload}
                          >
                            <p>Загрузить другую картинку</p>
                          </Upload>
                        </div>
                      </div>
                    ) : (
                      <Upload
                        showUploadList={false}
                        customRequest={asyncRequest}
                        listType="picture-card"
                        onChange={handleUpload}
                      >
                        <p
                          style={{
                            height: "150px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Загрузить картинку
                        </p>
                      </Upload>
                    )}
                    {window.location.origin === "https://ibazar.gscore.ru" ||
                     window.location.origin === "https://uzgen.gscore.ru" ||
                    window.location.origin === 'https://365meat.gscore.ru' || 
                    window.location.origin === 'https://mama.gscore.ru' || 
                    window.location.origin === 'https://kekeev.gscore.ru' || 
                    window.location.origin === 'https://ident.gscore.ru' || 
                    window.location.origin === 'https://pizzburg.gscore.ru' || 
                    window.location.origin === 'https://mamasochi.gscore.ru' || 
                    window.location.origin === 'https://test1.gscore.ru' || 
                    window.location.origin === 'https://test.sellkit.ru' || 
                    window.location.origin === 'https://test0panel.sellkit.ru' || 
                    window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://mangal-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
                    window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
                    window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
                    window.location.origin === 'https://simcity-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://darvin-panel.sellkit.ru' || 
                    window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda.gscore.ru' || 
                    window.location.origin === 'https://paulbaker.gscore.ru'|| 
                    window.location.origin === "https://iiko.gscore.ru" || 
                    window.location.origin === 'https://macarons.gscore.ru' ||
                    window.location.origin === "https://jonnytunec.gscore.ru/" ||
                    window.location.origin === "https://darvin.gscore.ru/" ||
                    window.location.origin === "https://pizzeria.gscore.ru" ||
                    window.location.origin === 'https://tigrus.gscore.ru' ||
                    window.location.origin === "https://osaka.gscore.ru" ||
                    window.location.origin === "https://ident.gscore.ru" ||
                    window.location.origin === "https://kekeev.gscore.ru" ||
                    window.location.origin === "https://uzgen.gscore.ru" ||
                    window.location.origin === "http://localhost:3000" ? (
                      <>
                        <Input
                          shadow={true}
                          maskType={String}
                          value={bannerText}
                          onChange={(e) => setBannerText(e.target.value)}
                          placeholder="Название"
                          style={{ marginTop: "20px" }}
                        />
                      </>
                    ) : null}
                    {window.location.origin === "https://ibazar.gscore.ru" ||
                     window.location.origin === "https://uzgen.gscore.ru" ||
                    window.location.origin === 'https://mama.gscore.ru' || 
                    window.location.origin === 'https://365meat.gscore.ru' || 
                    window.location.origin === 'https://kekeev.gscore.ru' || 
                    window.location.origin === 'https://ident.gscore.ru' || 
                    window.location.origin === 'https://pizzburg.gscore.ru' || 
                    window.location.origin === 'https://mamasochi.gscore.ru' || 
                    window.location.origin === 'https://test1.gscore.ru' || 
                    window.location.origin === 'https://test.sellkit.ru' || 
                    window.location.origin === 'https://test0panel.sellkit.ru' || 
                    window.location.origin === 'https://shtefan-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://mangal-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://mamasochi-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda-panel.sellkit.ru' || 
                    window.location.origin === 'https://aroundfire-panel.sellkit.ru' || 
                    window.location.origin === 'https://foodbowl-panel.sellkit.ru' || 
                    window.location.origin === 'https://simcity-panel.sellkit.ru' || 
                    window.location.origin === 'https://zafferano-panel.sellkit.ru' || 
                    window.location.origin === 'https://brofood-panel.sellkit.ru' || 
                    window.location.origin === 'https://darvin-panel.sellkit.ru' || 
                    window.location.origin === 'https://shaverno-panel.sellkit.ru' || 
                    window.location.origin === 'https://papapanda.gscore.ru' || 
                    window.location.origin === 'https://paulbaker.gscore.ru'|| 
                    window.location.origin === 'https://darvin.gscore.ru'|| 
                    window.location.origin === 'https://iiko.gscore.ru'||
                    window.location.origin === "https://jonnytunec.gscore.ru/" ||
                    window.location.origin === "https://kekeev.gscore.ru" ||
                    window.location.origin === "https://pizzeria.gscore.ru" ||
                    window.location.origin === 'https://tigrus.gscore.ru' ||
                    window.location.origin === "https://osaka.gscore.ru" || 
                    window.location.origin === 'https://macarons.gscore.ru' ||
                    window.location.origin === "http://localhost:3000" ? (
                      <Input
                        shadow={true}
                        maskType={String}
                        value={bannerUrl}
                        onChange={(e) => setBannerUrl(e.target.value)}
                        placeholder="URL"
                        style={{ marginTop: "20px" }}
                      />
                    ) : null}
                </Modal>
              </Modal>
            </div>
          </div>
        </div>
      </motion.div>
    );
  } else {
    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        exit={{ opacity: 0 }}
        className="page"
      >
        <div className="pageBody">
          <div className="pageBody-content">
            <h2>Редактировать баннеры</h2>
            <GridContextProvider onChange={handleDataBannerOrderChange}>
              <GridDropZone
                boxesPerRow={4}
                rowHeight={200}
                style={{ height: data.length > 0 ? "400px" : "200px" }}
              >
                {data?.map((c, i) => (
                  <GridItem key={i}>
                    <div
                      style={{
                        // width: c.width,
                        // height: c.height,
                        display: "block",
                      }}
                    >
                      <div
                        style={{
                          border: "2px solid gray",
                          // width: c.width,
                          height: '150px',
                          padding: 12,
                          borderRadius: "12px",
                          position: "relative",
                          margin: 12,
                          pointerEvents: "none",
                        }}
                      >
                        <div
                          style={{
                            position: "absolute",
                            top: -12,
                            cursor: "pointer",
                            right: -12,
                            background: "#DC143C",
                            height: 26,
                            width: 26,
                            borderRadius: "50%",
                            color: "white",
                            fontWeight: 500,
                            textAlign: "center",
                            lineHeight: "24px",
                            pointerEvents: "auto",
                          }}
                          onClick={() => handleDelete(c.ID)}
                        >
                          x
                        </div>
                        <img src={`${c.image}`} width={'100%'} height={'100%'} />
                      </div>
                    </div>
                  </GridItem>
                ))}
                <GridItem>
                  <button
                    onClick={showModal}
                    style={{
                      height: "150px",
                      border: "1px dashed #aaa",
                      background: "none",
                      outline: "none",
                      cursor: "pointer",
                      backgroundColor: "#eee",
                      padding: "40px 50px 70px",
                    }}
                    type="button"
                  >
                    <BiPlus size={"24px"} color={"#7B99FF"} />
                    <div style={{ color: "#7B99FF", fontWeight: 600 }}>
                      Добавить баннер
                    </div>
                  </button>
                </GridItem>
              </GridDropZone>
            </GridContextProvider>
            <Modal
              title="Редактировать баннер"
              open={isEditModalOpen}
              onCancel={handleCancelEditBanner}
              width={480}
              footer={[
                <button
                  type={"button"}
                  onClick={editBanner}
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(123, 153, 255)",
                    border: "none",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "7px 0",
                    cursor: "pointer",
                    marginBottom: "10px",
                  }}
                >
                  Сохранить
                </button>,
                <button
                  type={"button"}
                  onClick={handleCancelEditBanner}
                  style={{
                    width: "100%",
                    backgroundColor: "rgb(255, 77, 79)",
                    border: "none",
                    color: "#fff",
                    fontWeight: "600",
                    padding: "7px 0",
                    cursor: "pointer",
                  }}
                >
                  Отмена
                </button>,
              ]}
            >
              {tempEditImage ? (
                <div className="banners__pic-item">
                  <img src={tempEditImage} alt="" />
                  <Upload
                    className="baners__pic-item--new"
                    showUploadList={false}
                    customRequest={asyncRequest}
                    listType="picture-card"
                    onChange={handleEditUpload}
                  >
                    <p>Загрузить другую картинку</p>
                  </Upload>
                </div>
              ) : (
                <Upload
                  showUploadList={false}
                  customRequest={asyncRequest}
                  listType="picture-card"
                  onChange={handleEditUpload}
                >
                  <p
                    style={{
                      height: "150px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Загрузить картинку
                  </p>
                </Upload>
              )}
              {checkDom(
                "https://ibazar.gscore.ru",
                <Input
                  shadow={true}
                  maskType={String}
                  value={bannerEditText}
                  onChange={(e) => setBannerEditText(e.target.value)}
                  placeholder="Название"
                  style={{ marginTop: "20px" }}
                />
              )}
            </Modal>
            <div style={{ width: 250, height: "250px" }}>
              <Modal
                title="Добавить баннер"
                open={isModalOpen}
                onCancel={handleCancel}
                width={480}
                footer={[
                  <button
                    type={"button"}
                    onClick={handleSave}
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(123, 153, 255)",
                      border: "none",
                      color: "#fff",
                      fontWeight: "600",
                      padding: "7px 0",
                      cursor: "pointer",
                      marginBottom: "10px",
                    }}
                  >
                    Сохранить
                  </button>,
                  <button
                    type={"button"}
                    onClick={handleCancel}
                    style={{
                      width: "100%",
                      backgroundColor: "rgb(255, 77, 79)",
                      border: "none",
                      color: "#fff",
                      fontWeight: "600",
                      padding: "7px 0",
                      cursor: "pointer",
                    }}
                  >
                    Отмена
                  </button>,
                ]}
              >
                {tempImage ? (
                  <div className="banners__pic-item">
                    <img src={tempImage} alt="" />
                    <div className="baners__pic-item--backdrop">
                      <Upload
                        className="baners__pic-item--new"
                        showUploadList={false}
                        customRequest={asyncRequest}
                        listType="picture-card"
                        onChange={handleUpload}
                      >
                        <p>Загрузить другую картинку</p>
                      </Upload>
                    </div>
                  </div>
                ) : (
                  <Upload
                    showUploadList={false}
                    customRequest={asyncRequest}
                    listType="picture-card"
                    onChange={handleUpload}
                  >
                    <p
                      style={{
                        height: "150px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      Загрузить картинку
                    </p>
                  </Upload>
                )}
                {checkDom(
                  "https://ibazar.gscore.ru",
                  <Input
                    shadow={true}
                    maskType={String}
                    value={bannerText}
                    onChange={(e) => setBannerText(e.target.value)}
                    placeholder="Название"
                    style={{ marginTop: "20px" }}
                  />
                )}
              </Modal>
            </div>
          </div>
        </div>
      </motion.div>
    );
  }
};

export default Banners;
