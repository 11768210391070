import React, { useState, useEffect } from "react";
import Button from "../../components/Button/Button";
import Input from "../../components/Input/Input";
import TextArea from "antd/lib/input/TextArea";
import { useSelector } from "react-redux";
import { notification } from "antd";
import "./Loyalty.scss";
import { id } from "date-fns/locale";
import "react-quill/dist/quill.snow.css";
import parse from "html-react-parser";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";

const AddLoyalty = () => {
  const nav = useNavigate();
  const { token } = useSelector((state) => state);
  const [title, setTitle] = useState("");
  const [cost, setCost] = useState("");
  const [discount, setDiscount] = useState("");

  const onSubmit = async () => {
    try {

      const response = await fetch(
        `${process.env.REACT_APP_HOST ?? window.location.origin}/NewAdminPanel/settings/addLoyalty`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            title: title,
            total_cost: cost,
            discount_percentage: discount
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Ошибка HTTP: " + response.status);
      }

      const responseData = await response.json();
      console.log(responseData);
      // notification.success({ message: "Вакансия успешно добавлена" });
      nav("/loyalty");
      // setTitle("");
      // setDescr("");
    } catch (error) {
      console.error("Ошибка при получении страницы:", error);
    }
  };

  return (
    <div style={{ paddingTop: "110px", paddingLeft: "20px", width: "100%" }}>
      <Button text={"Вернуться"} onClick={() => window.history.back()} />
      <div
        style={{
          background: "white",
          padding: "24px 16px",
          borderRadius: "12px",
          width: "800px",
          margin: "20px auto 0",
          display: "block",
        }}
      >
        <h2 style={{ fontSize: "20px", textAlign: "center" }}>Добавление</h2>
        <div
          style={{
            display: "flex",
            gap: "20px",
            flexWrap: "wrap",
            flexDirection: "column",
            width: "75%",
            margin: "20px auto 0",
          }}
        >
          <Input
            style={{
              border: "1.5px solid rgba(176, 194, 255, .6)",
              borderRadius: "13px",
            }}
            placeholder={"Название"}
            maskType={String}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Input
            style={{
              border: "1.5px solid rgba(176, 194, 255, .6)",
              borderRadius: "13px",
            }}
            placeholder={"Общая стоимость покупок"}
            maskType={Number}
            value={cost}
            onChange={(e) => setCost(e.target.value)}
          />
          <Input
            style={{
              border: "1.5px solid rgba(176, 194, 255, .6)",
              borderRadius: "13px",
            }}
            placeholder={"Процент скидки"}
            maskType={Number}
            value={discount}
            onChange={(e) => setDiscount(e.target.value)}
          />
           <div style={{ margin: "0 auto" }}>
            <Button text={"Создать"} onClick={onSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLoyalty;
